exports.components = {
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-blog-archive-js": () => import("./../../../src/templates/blog/archive.js" /* webpackChunkName: "component---src-templates-blog-archive-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog/post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-shop-category-js": () => import("./../../../src/templates/shop/category.js" /* webpackChunkName: "component---src-templates-shop-category-js" */),
  "component---src-templates-shop-collection-js": () => import("./../../../src/templates/shop/collection.js" /* webpackChunkName: "component---src-templates-shop-collection-js" */),
  "component---src-templates-shop-product-js": () => import("./../../../src/templates/shop/product.js" /* webpackChunkName: "component---src-templates-shop-product-js" */),
  "component---src-templates-shop-profile-js": () => import("./../../../src/templates/shop/profile.js" /* webpackChunkName: "component---src-templates-shop-profile-js" */)
}

