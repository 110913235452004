import React from 'react'
import i18next from 'i18next'
import {I18nextProvider, initReactI18next} from 'react-i18next'
import {Helmet} from 'react-helmet'
import {parseISO, format} from 'date-fns'

export const AlternateLinksContext = React.createContext([])

const withI18next = (i18n, context) => children => {
  return (
    <I18nextProvider i18n={i18n}>
      <AlternateLinksContext.Provider value={context && context.alternateLinks}>
        {
          <Helmet>
            {context &&
              context.alternateLinks &&
              context.alternateLinks.map(link => (
                <link
                  key={link.language}
                  rel="alternate"
                  hrefLang={`${link.language}`}
                  href={`https://bannouheol.com` + link.path}
                />
              ))}
            {context && context.canonicalUrl && (
              <link rel="canonical" href={`https://bannouheol.com` + context.canonicalUrl} />
            )}
            {context && context.canonicalUrl && (
              <meta property="og:url" content={`https://bannouheol.com` + context.canonicalUrl} />
            )}
            {context.language && <meta property="og:locale" content={`${context.language}_FR`} />}
            {context &&
              context.alternateLinks &&
              context.alternateLinks.map(link => {
                return link.language !== context.language ? (
                  <meta key={link.language} property="og:locale:alternate" content={`${link.language}_FR`} />
                ) : null
              })}
          </Helmet>
        }
        {children}
      </AlternateLinksContext.Provider>
    </I18nextProvider>
  )
}

export function wrapPageElement({element, props}) {
  const language = props.pageContext.language ? props.pageContext.language : 'fr'

  const i18n = i18next.createInstance().use(initReactI18next)

  i18n.init({
    debug: process.env.DEBUG,
    lng: language,
    interpolation: {
      escapeValue: false,
      format: function (value, f, _lng) {
        if (value instanceof Date) return format(parseISO(value), f)
        return value
      },
    },
    initImmediate: false,
    resources: props.pageContext && props.pageContext.i18nResources,
    react: {
      useSuspense: false,
    },
  })
  return withI18next(i18n, props.pageContext ? props.pageContext : null)(element)
}
