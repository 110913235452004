import * as Sentry from '@sentry/gatsby'

Sentry.init({
  dsn: 'https://5055eb58ebea2e2b99f5054e9a8040d5@o336249.ingest.sentry.io/4506542643412992',
  //integrations: [new Sentry.Replay()],x

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})
