//import { tailwind as base } from "@theme-ui/presets"
import '../assets/css/reset.css'
import '../assets/css/fonts.css'
import '../assets/css/zoomFix.css'
import '@fontsource/quicksand'
import '@fontsource/open-sans/300.css'

const config = {
  borderWidths: {
    0: '0',
    2: '2px',
    4: '4px',
    8: '8px',
    px: '1px',
  },
  breakpoints: ['640px', '768px', '1024px', '1280px', '1536px'],
  colors: {
    transparent: 'transparent',
    black: '#000',
    white: '#fff',
    tomato: '#ff6347',
    red: '#FE5F55',
    background: '#fff',
    primary: '#FBAF16', //https://infographic.likitimavm.com/color-palettes-for-2020/
    primaryHover: '#853F75',
    secondary: '#40C9A2', //"#8ACCB1", //https://infographic.likitimavm.com/color-palettes-for-2020/
    green: '#8ACCB1',
    grey: '#353B50',
    bluebird: '#246EB9', //"#89B1D8", //https://www.behr.com/consumer/inspiration/2020-color-trends/
    grayDark: '#2d3748',
    text: '#222',
    muted: 'rgba(255,255,255,0.125)',
    success: '#9ae6b4',
    info: '#63b3ed',
    warning: '#faf089',
    danger: '#feb2b2',
    light: '#f7fafc',
    dark: '#2d3748',
    textMuted: '#8189A9',
  },
  layout: {
    headerFirst: {
      color: 'white',
      bg: 'primary',
    },
    headerSecond: {
      color: 'white',
      bg: 'secondary',
      a: {
        color: 'inherit',
      },
      'button:hover > div:last-child, a:hover': {
        color: 'white',
        textDecoration: 'none',
      },
      'form + div': {
        m: 2,
        a: {
          color: 'text',
          '&:hover': {
            color: 'secondary',
          },
        },
        button: {
          bg: 'secondary',
          color: 'white',
          p: 2,
          borderRadius: 4,
          border: 0,
          fontSize: 1,
        },
      },
    },
    mobileMenu: {
      bg: 'secondary',
      position: 'fixed',
      height: '100%',
      display: ['block', 'none'],
      p: 4,
      color: 'white',
      a: {
        color: 'white',
        py: 3,
        borderColor: 'rgba(255,255,255,0.125)',
      },
    },
    footerWrap: {
      color: 'white',
    },
    footerFirst: {
      color: 'white',
      bg: 'bluebird',
      p: 4,
      display: 'flex',
      flexWrap: 'wrap',
      a: {
        color: 'white',
        pr: 3,
      },
    },
    footerSecond: {
      bg: 'secondary',
      p: 4,
      display: 'flex',
      flexWrap: 'wrap',
      fontSize: 1,
    },
  },
  boxes: {
    important: {
      p: [4, 3, 3, 4],
      width: ['full', 'auto'],
      bg: 'grey',
      color: 'background',
      fontWeight: 'bold',
      boxShadow: '0px 10px 10px rgba(0, 0, 0, .125)',
      display: 'inline-block',
    },
  },
  cards: {
    primary: {
      mb: 3,
      '&:hover': {
        transition: 'transform .5s ease',
        transform: 'scale(1.01)',
        img: {},
      },
    },
    compact: {
      padding: 1,
      borderRadius: 2,
      border: '1px solid',
      borderColor: 'muted',
      mb: 2,
    },
  },
  badges: {
    primary: {
      color: 'background',
      bg: 'primary',
      fontSize: 0,
    },
    outline: {
      color: 'primary',
      bg: 'transparent',
      boxShadow: 'inset 0 0 0 1px',
    },
  },
  links: {
    header: {
      fontWeight: '600',
      color: 'text',
      fontSize: 'inherit',
      py: 1,
      ml: 2,
      borderBottom: '2px solid transparent',
      transition: 'background-color .2s ease',
      '&:hover': {
        color: 'text',
        borderColor: 'text',
        textDecoration: 'none',
      },
      '&.active': {
        color: 'text',
        borderColor: 'text',
      },
    },
    footer: {
      color: 'white',
      textDecoration: 'none',
      pr: 3,
    },
    product: {
      fontWeight: '600',
      color: 'black',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
  images: {
    card: {
      borderRadius: 5,
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.125)',
      '&:hover': {
        boxShadow: '0 0 8px rgba(0, 0, 0, 0.250)',
        transition: 'all 0.2s ease',
      },
    },
    avatar: {
      width: 128,
      height: 128,
      borderRadius: 99999,
    },
  },
  fonts: {
    sans: 'system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
    serif: 'Georgia,Cambria,"Times New Roman",Times,serif',
    mono: 'Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace',
    monospace: 'Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace',
    //body:
    // 'system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
    heading: '"Quicksand", sans-serif',
    body: '"Open Sans", sans-serif',
    logo: '"Comics", sans-serif',
  },
  fontSizes: ['0.775rem', '0.875rem', '1rem', '1.25rem', '1.5rem', '1.875rem', '2rem', '2.25rem', '2.5rem', '2.75rem'],
  fontWeights: {
    hairline: '100',
    thin: '200',
    light: '300',
    normal: '400',
    medium: '500',
    semibold: '600',
    bold: '700',
    extrabold: '800',
    black: '900',
    body: '400',
    heading: '700',
  },
  letterSpacings: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
  },
  lineHeights: {
    none: '1',
    tight: '1.25',
    snug: '1.375',
    normal: '1.5',
    relaxed: '1.625',
    loose: '2',
    body: '1.5',
    heading: '1.25',
  },
  sizes: {
    0: '0',
    1: '0.25rem',
    2: '0.5rem',
    3: '0.75rem',
    4: '1rem',
    5: '1.25rem',
    6: '1.5rem',
    8: '2rem',
    10: '2.5rem',
    12: '3rem',
    16: '4rem',
    20: '5rem',
    24: '6rem',
    32: '8rem',
    40: '10rem',
    48: '12rem',
    56: '14rem',
    64: '16rem',
    px: '1px',
    xs: '20rem',
    sm: '24rem',
    md: '28rem',
    lg: '32rem',
    xl: '36rem',
    '2xl': '42rem',
    '3xl': '48rem',
    '4xl': '56rem',
    '5xl': '64rem',
    '6xl': '72rem',
    '1/2': '50%',
    '1/3': '33.333333%',
    '2/3': '66.666667%',
    '1/4': '25%',
    '2/4': '50%',
    '3/4': '75%',
    '1/5': '20%',
    '2/5': '40%',
    '3/5': '60%',
    '4/5': '80%',
    '1/6': '16.666667%',
    '2/6': '33.333333%',
    '3/6': '50%',
    '4/6': '66.666667%',
    '5/6': '83.333333%',
    '1/12': '8.333333%',
    '2/12': '16.666667%',
    '3/12': '25%',
    '4/12': '33.333333%',
    '5/12': '41.666667%',
    '6/12': '50%',
    '7/12': '58.333333%',
    '8/12': '66.666667%',
    '9/12': '75%',
    '10/12': '83.333333%',
    '11/12': '91.666667%',
    full: '100%',
    screenHeight: '100vh',
    screenWidth: '100vw',
  },
  shadows: {
    xs: '0 0 0 1px rgba(0, 0, 0, 0.05)',
    sm: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
    default: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    xl: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
    inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
    outline: '0 0 0 3px rgba(66, 153, 225, 0.5)',
    none: 'none',
  },
  space: [0, '0.25rem', '0.5rem', '1rem', '2rem', '4rem', '8rem', '16rem', '32rem'],
  radii: {
    none: '0',
    sm: '0.125rem',
    default: '0.25rem',
    md: '0.375rem',
    lg: '0.5rem',
    full: '9999px',
  },
  zIndices: {
    0: '0',
    10: '10',
    20: '20',
    30: '30',
    40: '40',
    50: '50',
    auto: 'auto',
  },
  styles: {
    root: {
      body: {
        fontFamily: 'body',
        lineHeight: 'body',
        fontWeight: 'body',
      },
      fontSize: 2,
      h1: {
        fontFamily: 'heading',
        fontWeight: 'heading',
        lineHeight: 'heading',
        m: 0,
        mb: 3,
        fontSize: 6,
        mt: 1,
      },
      h2: {
        fontFamily: 'heading',
        fontWeight: 'heading',
        lineHeight: 'heading',
        m: 0,
        mb: 2,
        fontSize: 5,
        mt: 1,
      },
      h3: {
        fontFamily: 'heading',
        fontWeight: 'heading',
        lineHeight: 'heading',
        m: 0,
        mb: 2,
        fontSize: 4,
        mt: 3,
      },
      h4: {
        fontFamily: 'heading',
        fontWeight: 'heading',
        lineHeight: 'heading',
        m: 0,
        mb: 2,
        fontSize: 3,
      },
      h5: {
        fontFamily: 'heading',
        fontWeight: 'heading',
        lineHeight: 'heading',
        m: 0,
        mb: 1,
        fontSize: 2,
      },
      h6: {
        fontFamily: 'heading',
        fontWeight: 'heading',
        lineHeight: 'heading',
        m: 0,
        mb: 2,
        fontSize: 1,
      },
      a: {
        color: 'secondary',
        textDecoration: 'none',
        ':hover': {
          textDecoration: 'underline',
        },
        ':focus': {
          outline: 'none',
        },
      },
      strong: {
        fontWeight: 'bold',
      },
      p: {
        my: 2,
      },
    },
    hr: {
      bg: 'muted',
      border: 0,
      height: '1px',
      m: 3,
    },
  },
  buttons: {
    primary: {
      borderRadius: 8,
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      color: 'background',
      fontSize: 'inherit',
      bg: 'primary',
      '&:hover': {
        bg: 'tomato',
      },
    },
    secondary: {
      color: 'background',
      bg: 'secondary',
    },
    simple: {
      py: 2,
      px: 3,
      cursor: 'pointer',
      fontSize: '100%',
      lineHeight: 'inherit',
      backgroundColor: 'primary',
      border: 'none',
      color: 'white',
      fontWeight: 'bold',
      borderRadius: 'default',
      '&:hover': {
        backgroundColor: 'primaryHover',
      },
    },
    pill: {
      py: 2,
      px: 3,
      cursor: 'pointer',
      fontSize: '100%',
      lineHeight: 'inherit',
      backgroundColor: 'primary',
      border: 'none',
      color: 'white',
      fontWeight: 'bold',
      borderRadius: 'full',
      '&:hover': {
        backgroundColor: 'primaryHover',
      },
    },
    discrete: {
      p: 0,
      px: 1,
      display: 'inline-block',
      bg: 'transparent',
      color: 'grey',
      border: '1px solid rgba(200,200,200,0.325)',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    buy: {
      py: 1,
      px: 2,
      fontSize: 0,
      display: 'block',
      bg: 'transparent',
      color: 'black',
      border: '1px solid rgba(200,200,200,0.525)',
      '&:hover': {
        cursor: 'pointer',
        bg: 'black',
        color: 'white',
        borderColor: 'black',
      },
    },
    outline: {
      py: 2,
      px: 3,
      cursor: 'pointer',
      fontSize: '100%',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'primary',
      color: 'primary',
      fontWeight: 'semibold',
      borderRadius: 'default',
      '&:hover': {
        backgroundColor: 'primary',
        color: 'white',
        borderColor: 'transparent',
      },
    },
    bordered: {
      py: 2,
      px: 3,
      cursor: 'pointer',
      fontSize: '100%',
      lineHeight: 'inherit',
      backgroundColor: 'primary',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'primaryHover',
      color: 'white',
      fontWeight: 'bold',
      borderRadius: 'default',
      '&:hover': {
        backgroundColor: 'primaryHover',
      },
    },
    disabled: {
      py: 2,
      px: 3,
      cursor: 'not-allowed',
      fontSize: '100%',
      lineHeight: 'inherit',
      backgroundColor: 'primary',
      border: 'none',
      opacity: 0.5,
      color: 'white',
      fontWeight: 'bold',
      borderRadius: 'default',
    },
    '3D': {
      py: 2,
      px: 3,
      cursor: 'pointer',
      fontSize: '100%',
      lineHeight: 'inherit',
      backgroundColor: 'primary',
      border: 'none',
      borderBottomWidth: '4px',
      borderBottomStyle: 'solid',
      borderBottomColor: 'primaryHover',
      color: 'white',
      fontWeight: 'bold',
      borderRadius: 'default',
      transition: 'transform 0.3s ease-in-out',
      '&:hover': {
        transform: 'translateY(-1px)',
      },
    },
    elevated: {
      py: 2,
      px: 3,
      cursor: 'pointer',
      fontSize: '100%',
      lineHeight: 'inherit',
      backgroundColor: 'white',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'gray.4',
      color: 'text',
      fontWeight: 'bold',
      borderRadius: 'default',
      boxShadow: 'default',
      '&:hover': {
        backgroundColor: 'gray.1',
      },
    },
  },
  inputs: {
    shadow: {
      py: 2,
      px: 3,
      fontSize: '100%',
      borderRadius: 'default',
      appearance: 'none',
      lineHeight: 'tight',
      border: 'none',
      color: 'text',
      boxShadow: 'default',
      '&:focus': {
        outline: 'none',
        boxShadow: 'outline',
      },
      '&::placeholder': {
        color: 'rgba(255,255,255,0.5)',
      },
    },
    inline: {
      py: 2,
      px: 3,
      fontSize: '100%',
      borderRadius: 'default',
      appearance: 'none',
      lineHeight: 'tight',
      backgroundColor: 'transparent',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: 'gray.2',
      color: 'white',
      '&:focus': {
        color: 'text',
        outline: 'none',
        borderColor: 'primary',
        backgroundColor: 'white',
      },
      '&::placeholder': {
        color: 'white',
      },
    },
    underline: {
      py: 2,
      px: 3,
      fontSize: '100%',
      borderRadius: '0px',
      appearance: 'none',
      lineHeight: 'tight',
      backgroundColor: 'transparent',
      border: 'none',
      borderBottomWidth: '2px',
      borderBottomStyle: 'solid',
      borderBottomColor: 'primary',
      color: 'gray.7',
      '&:focus': {
        outline: 'none',
        borderColor: 'primary',
        backgroundColor: 'white',
      },
    },
  },
  transforms: {
    transformOrigin: {
      center: 'center',
      top: 'top',
      'top-right': 'top right',
      right: 'right',
      'bottom-right': 'bottom right',
      bottom: 'bottom',
      'bottom-left': 'bottom left',
      left: 'left',
      'top-left': 'top left',
    },
    translate: {
      0: '0',
      1: '0.25rem',
      2: '0.5rem',
      3: '0.75rem',
      4: '1rem',
      5: '1.25rem',
      6: '1.5rem',
      8: '2rem',
      10: '2.5rem',
      12: '3rem',
      16: '4rem',
      20: '5rem',
      24: '6rem',
      32: '8rem',
      40: '10rem',
      48: '12rem',
      56: '14rem',
      64: '16rem',
      px: '1px',
      '-full': '-100%',
      '-1/2': '-50%',
      '1/2': '50%',
      full: '100%',
    },
    scale: {
      0: '0',
      50: '.5',
      75: '.75',
      90: '.9',
      95: '.95',
      100: '1',
      105: '1.05',
      110: '1.1',
      125: '1.25',
      150: '1.5',
    },
    rotate: {
      0: '0',
      45: '45deg',
      90: '90deg',
      180: '180deg',
      '-180': '-180deg',
      '-90': '-90deg',
      '-45': '-45deg',
    },
    skew: {
      0: '0',
      3: '3deg',
      6: '6deg',
      12: '12deg',
      '-12': '-12deg',
      '-6': '-6deg',
      '-3': '-3deg',
    },
  },
  transitions: {
    property: {
      none: 'none',
      all: 'all',
      default: 'background-color, border-color, color, fill, stroke, opacity, box-shadow, transform',
      colors: 'background-color, border-color, color, fill, stroke',
      opacity: 'opacity',
      shadow: 'box-shadow',
      transform: 'transform',
    },
    timingFunction: {
      linear: 'linear',
      in: 'cubic-bezier(0.4, 0, 1, 1)',
      out: 'cubic-bezier(0, 0, 0.2, 1)',
      'in-out': 'cubic-bezier(0.4, 0, 0.2, 1)',
    },
    duration: {
      75: '75ms',
      100: '100ms',
      150: '150ms',
      200: '200ms',
      300: '300ms',
      500: '500ms',
      700: '700ms',
      1000: '1000ms',
    },
  },
}

export default config
